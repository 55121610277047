<template>
  <a-modal
    :width="600"
    v-model="localVisible"
    title="Process new Buy-Order"
    :afterClose="handleVisibleChange"
    :footer="null"
  >
    <a-form-model
      ref="makeBuyOrderForm"
      :model="makeOrderForm"
      :rules="makeOrderFormRules"
    >
      <a-row>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="clientId" label="Client" prop="client_id">
<!--            <a-input v-model="makeOrderForm.clientId" >-->
<!--              <a-icon slot="prefix" type="user" />-->
<!--            </a-input>-->
            <a-input-search
              v-model="clientSearch"
              placeholder="input client id and press enter"
              :disabled="clientLoading"
              @search="lookupClient"
            >
              <a-icon slot="prefix" type="user" />
              <a-button slot="enterButton">
                <a-icon v-if="clientLoading" type="loading" />
                <a-icon v-else type="search" />
              </a-button>
            </a-input-search>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="pr-2">
          <a-form-model-item ref="amount" label="Amount" prop="amount">
            <a-input-number
              v-model="makeOrderForm.amount"
              class="w-100"
              :default-value="1000"
              :step="0.01"
              :min="1"
              :max="100000"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-card v-if="client" title="Check client info">
      <a-row>
        <a-col :span="12">
          ClientId:<br>
          BroID:<br>
          ExtID:<br>
          Login/Username:<br>
          Name:<br>
          Phone:<br/>
          Project:<br>
          Currency:<br/>
          Has bromoney account:
        </a-col>
        <a-col :span="12">
          {{ client.id }}<br>
          {{ client.alt_id }}<br>
          {{ (client.ext_id.length > 16) ? client.ext_id.substr(0, 16) + '...' : client.ext_id }}<br>
          {{ client.login }}<br>
          {{ client.short_name ?? '-' }}<br>
          {{ client.phone ?? '-' }}<br>
          <span class="d-block">
            <span v-if="client.project.logo_url">
              <img :src="client.project.logo_url" width="16"/>
            </span> {{ client.project.name }}
          </span>
          <span class="d-block">{{ client.currency.name }}</span>
          <span class="d-block">
            <span v-if="client.parent_related" class="font-weight-bolder">
              <img v-if="client.parent_related.project.logo_url" :src="client.parent_related.project.logo_url"
                   width="14"/>
                  {{ client.parent_related.id }}
            </span>
            <span v-else>
              <a-tag color="orange">No</a-tag>
            </span>
          </span>
        </a-col>
      </a-row>
      <div v-if="client.parent_related && client.parent_related.balance" class="mt-3">
        BroMoney Info:
        <a-row class="mt-2">
          <a-col :span="12">
            <a-statistic title="Balance" :value="client.parent_related.balance.amount.toFixed(2)" style="margin-right: 50px">
              <template #suffix>
                $
              </template>
            </a-statistic>
            <span class="small d-block text-gray-5">
              <a-icon type="retweet" /> Rate: {{ client.parent_related.balance.rate }}
            </span>
          </a-col>
          <a-col :span="12">
            <a-statistic title="To withdraw"
              :value="(makeOrderForm.amount / client.parent_related.balance.rate).toFixed(2)"
            >
              <template #suffix>
                $
              </template>
            </a-statistic>
            <span class="small d-block"
                  v-if="parseFloat((makeOrderForm.amount / client.parent_related.balance.rate).toFixed(2)) > client.parent_related.balance.amount"
            >
              <a-icon type="warning" class="text-warning" /> Not enough balance
            </span>
          </a-col>
        </a-row>
      </div>
      <a slot="extra" href="#">
        <a-button v-if="!clientValidated" size="small" class="text-success" @click="confirmClient">
          <a-icon type="check" /> Confirm
        </a-button>
      </a>
    </a-card>
    <hr/>
    <a-row class="mt-4">
      <a-col :span="8">
        <a-button @click="closeModal">
          Cancel
        </a-button>
      </a-col>
      <a-col :span="16" class="text-right">
        <a-button :disabled="!clientValidated" type="primary" @click="onSubmit" :loading="submitLoading">
          Create
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import ApiService from '@/services/api/api.service'

export default {
  name: 'makeBuyOrderModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    // ---
  },
  watch: {
    visible(newValue) {
      this.localVisible = newValue
    },
  },
  data() {
    return {
      localVisible: this.visible,
      submitLoading: false,
      clientLoading: false,
      clientSearch: '',
      client: undefined,
      clientValidated: false,
      makeOrderForm: {
        amount: 500,
        clientId: 0,
      },
      makeOrderFormRules: {
        amount: [{ required: true, message: 'Please enter sum', trigger: 'blur' }],
      },
    }
  },
  mounted () {
    //
  },
  methods: {
    closeModal() {
      this.handleVisibleChange()
    },
    clearFields() {
      this.client = undefined
      this.clientValidated = false
      this.clientSearch = ''
      this.makeOrderForm.clientId = 0
      this.makeOrderForm.amount = 500
    },
    handleVisibleChange() {
      this.$emit('update:visible', false)
      this.clearFields()
    },
    submitModal(record, amount) {
      return this.submitMakeOrder(record, amount)
    },
    submitMakeOrder(record, amount) {
      this.$emit('submitFunction', record, amount)
      this.handleVisibleChange()
    },
    onSubmit() {
      this.$refs.makeBuyOrderForm.validate(valid => {
        if (valid) {
          this.submitOperation()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submitOperation() {
      this.submitLoading = true
      return ApiService.makeNewBuyOrder(
        this.makeOrderForm.clientId, this.makeOrderForm.amount,
      ).then((response) => {
        this.$message.success(`Succeeded new operation: ${response.amount} ${response.currency.name} for clientId: ` + this.makeOrderForm.clientId)
        this.submitModal(response, this.makeOrderForm.amount)
      }).catch(error => {
        this.$message.error('Error creating operation: ' + error.message)
      }).finally(() => {
        this.submitLoading = false
      })
    },
    confirmClient() {
      this.makeOrderForm.clientId = this.client.id
      this.clientValidated = true
    },
    lookupClient() {
      this.client = undefined
      this.clientValidated = false
      this.makeOrderForm.clientId = 0

      this.clientLoading = true
      return ApiService.newBuyOrderLookupClient(
        this.clientSearch,
      ).then((response) => {
        this.client = response
      }).catch(error => {
        this.$message.error('Error getting client info: ' + error.message)
      }).finally(() => {
        this.clientLoading = false
      })
    },
  },
}
</script>

<style scoped>
</style>
