<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="h5 mb-0"><a-icon type="bank" /> Payment methods</span>
      <div class="">
        <a-radio-group v-model="targetType" button-style="solid" @change="targetTypeChanged">
          <a-radio-button value="p2p">
            <a-icon type="bank" /> P2P
          </a-radio-button>
          <a-radio-button value="wallet">
            <a-icon type="wallet" /> Wallet
          </a-radio-button>
        </a-radio-group>
      </div>
      <a-button type="primary" @click="showNewPsystemModal">
        <a-icon type="plus" /> Add
      </a-button>
    </div>
    <a-table :columns="psystemsColumns" :dataSource="psystemsData"
             :rowKey="record => record.id"
             :components="components"
             :pagination="pagination"
             :loading="psystemLoading"
    >
      <template v-slot:id="id, record">
        <span v-if="record.img_path" class="font-size-12">
          <img :src="record.img_path" width="32" height="32" />
        </span>
      </template>
      <template v-slot:name="name, record">
        <a-tooltip :title="record.sys_name" placement="bottom">
          <span class="font-weight-bolder">{{ name }}</span>
        </a-tooltip>
        <span class="d-block small">
<!--          <a-tag>-->
<!--            <a-icon type="user" v-if="record.target_type === 'p2p'" />-->
<!--            <a-icon type="wallet" v-if="record.target_type === 'wallet'" />-->
<!--            {{ record.target_type }}-->
<!--          </a-tag>-->
          <a-tag>{{ record.sys_name }}</a-tag>
        </span>
      </template>
      <template v-slot:deeplink="deeplink">
        <span v-if="deeplink" class="font-size-12"><a-icon type="link" /> {{ deeplink }}</span>
      </template>
      <template v-slot:address_types="address_types">
        <span v-if="address_types">{{ address_types.map(x=>x['slug']).join(', ') }}</span>
      </template>
      <template v-slot:currencies="currencies">
        {{ currencies.map(e => e.name).join(', ') }}
      </template>
      <template v-slot:operation="text, record">
          <span>
              <a-tooltip title="Activate / Deactivate" placement="bottom">
                <a-switch v-model="record.is_active" size="small" :psId="record.id" @change="psystemActiveState" />
              </a-tooltip>
          </span>
          <span class="ml-3">
              <a-tooltip title="Edit" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18 text-primary" @click="showEditPsystemModal(record)"><a-icon type="edit" theme="filled" /></a>
              </a-tooltip>
          </span>
          <span class="ml-3">
            <a-tooltip title="Delete" placement="bottom">
              <a-popconfirm
                :title="`Delete pay method ${record.name}?`"
                @confirm="deletePsystem(record)"
              >
                <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
              </a-popconfirm>
            </a-tooltip>
          </span>
        </template>
    </a-table>
    <a-modal v-model="psystemModal"
             :destroyOnClose="true"
             :title="psystemObj ? `Edit payment method '${psystemObj.name}'` : 'Add new payment method'"
             :footer="null"
             class="psystem-modal"
             width="680px"
    >
      <psystem-modal-component
        :psystem-info="psystemObj"
        @closeModalFunction="closePsystemModal"
        @submitModalFunction="psystemModalSubmit" />
    </a-modal>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import psystemModalComponent from '@/views/psystems/psystems/psystemModal.vue'
import DraggableWrapper from './DraggableWrapper'
import ApiService from '@/services/api/api.service'

const psystemsColumns = [
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    width: '52px',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Currencies',
    dataIndex: 'currencies',
    key: 'currencies',
    width: '20%',
    scopedSlots: { customRender: 'currencies' },
  },
  {
    title: 'Address types',
    dataIndex: 'address_types',
    key: 'address_types',
    scopedSlots: { customRender: 'address_types' },
  },
  {
    title: 'Code (NSPK)',
    dataIndex: 'nspk_code',
    key: 'nspk_code',
    scopedSlots: { customRender: 'nspk' },
  },
  {
    title: 'Action',
    dataIndex: 'operation',
    width: '20%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]

export default {
  name: 'psystemsTable',
  components: {
    psystemModalComponent,
  },
  computed: {
    // ---
  },
  provide() {
    return {
      data: this,
    }
  },
  data() {
    return {
      psystemsData: [],
      psystemsColumns,
      psystemModal: false,
      psystemLoading: false,
      psystemSubmitLoading: false,
      psystemObj: undefined,
      pagination: { pageSize: 100 },
      showForActiveOnly: false,
      showForCurrencyId: false,
      targetType: 'p2p',
      components: {
        body: {
          wrapper: DraggableWrapper,
        },
      },
    }
  },
  mounted () {
    // ---
    this.getPsystems()
  },
  methods: {
    showNewPsystemModal() {
      this.psystemObj = null
      this.psystemModal = true
    },
    showEditPsystemModal(psystem) {
      this.psystemObj = psystem
      this.psystemModal = true
    },
    closePsystemModal() {
      this.psystemModal = false
    },
    async targetTypeChanged(e) {
      console.log(e.target.value)
      await this.getPsystems()
    },
    psystemModalSubmit(psystem, edit) {
      if (edit) {
        const psIndex = this.psystemsData.findIndex(obj => obj.id === psystem.id)
        this.psystemsData[psIndex] = psystem
        console.log(this.psystemsData)
      } else {
        this.getPsystems()
      }
      this.$emit('changeTableFunction')
      this.psystemModal = false
    },
    async getPsystems() {
      this.psystemLoading = true
      return ApiService.getPsystems(this.showForActiveOnly, this.showForCurrencyId, this.targetType).then((response) => {
        this.psystemsData = response
        this.psystemLoading = false
      }).catch(error => { console.log(error); this.psystemLoading = false })
    },
    deletePsystem(psystem) {
      const url = '/admin/ps/' + psystem.id
      apiClient.delete(url).then((response) => {
        console.log(response.data)
        // if ((response.data.error) && (!data.error.message))
        this.$notification.success({
          message: 'Pay method successfully deleted',
          description: psystem.name,
        })
        const psIndex = this.psystemsData.findIndex(obj => obj.id === psystem.id)
        this.psystemsData.splice(psIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error on delete pay method',
          description: error.message,
        })
      })
    },
    psystemActiveState(checked, event) {
      const psId = event.target.getAttribute('psId')
      const url = '/admin/ps/' + psId + '/active/' + +checked
      apiClient.get(url).then((response) => {
        this.$notification.success({
          message: 'Pay method status changed',
          description: response.data.data.name,
        })
        const psIndex = this.psystemsData.findIndex(obj => obj.id === psId)
        this.psystemsData[psIndex] = response.data.data
        console.log(this.psystemsData[psIndex])
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error changing status',
          description: error.message,
        })
      })
    },
  },
}
</script>

<style scoped>
</style>
