var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.wrapperLog},[_c('div',{staticClass:"text-center"},[_c('h1',{class:_vm.$style.logo},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.settings.logo))])])]),_c('div',{staticClass:"card",class:_vm.$style.containerLog},[_c('div',{class:_vm.$style.title + ' font-weight-light'},[_c('a-icon',{attrs:{"type":"unlock"}}),_vm._v(" "+_vm._s(_vm.$t('common.authorization'))+" ")],1),_c('div',[_c('a-radio-group',{attrs:{"value":_vm.settings.authProvider},on:{"change":e => _vm.changeAuthProvider(e.target.value)}},[_c('a-radio',{attrs:{"value":"jwt","hidden":""}},[_vm._v("JWT")])],1)],1),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [{ required: true, message: 'Please input your username!' }]}]),expression:"['email', {rules: [{ required: true, message: 'Please input your username!' }]}]"}],attrs:{"size":"large","placeholder":"Email"}},[_c('a-icon',{staticClass:"text-primary",attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {rules: [{ required: true, message: 'Please input your Password!' }]}]),expression:"['password', {rules: [{ required: true, message: 'Please input your Password!' }]}]"}],attrs:{"size":"large","placeholder":"Password","type":"password"}},[_c('a-icon',{staticClass:"text-primary",attrs:{"slot":"prefix","type":"unlock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('div',{class:_vm.$style.checkbox},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remember',
                {
                  valuePropName: 'checked',
                  initialValue: true,
                },
              ]),expression:"[\n                'remember',\n                {\n                  valuePropName: 'checked',\n                  initialValue: true,\n                },\n              ]"}],attrs:{"disabled":true}},[_vm._v(_vm._s(_vm.$t('buttons.btnRememberMe'))+" ")])],1)]),_c('a-button',{class:_vm.$style.button,attrs:{"type":"primary","htmlType":"submit","size":"large","loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t('buttons.btnLogIn'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }